import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { Reason } from '../models/reason';
import { Router } from '@angular/router';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-reasons',
  standalone: true,
  imports: [CommonModule, FormsModule],
  templateUrl: './reasons.component.html',
  styleUrl: './reasons.component.css'
})
export class ReasonsComponent {
  _router: Router
  constructor( router: Router) {
    this._router = router
  }

  @Output()
  onComplete: EventEmitter<Reason> = new EventEmitter();

  comment:string=""


  complete(): void {
    let reason = {id:this.chosenReason?.id??0, label:`${this.chosenReason?.label} - comentário: ${this.comment}`}
    this.onComplete.emit(reason)
    this.navigate()
  }

  onChange(event: Reason): void {
    this.chosenReason = event

  }

  reasons: Reason[] = [
    {
      label: "Saí da empresa",
      id: 1,
    },
    {
      label: "Não preciso dos produtos da Pilla",
      id: 2,
    },
    {
      label: "Não tive acesso aos produtos que precisava",
      id: 3,
    },
    {
      label: "Não tive acesso aos produtos que precisava",
      id: 4,
    },
    {
      label: "Outro",
      id: 5,
    },
  ]
  chosenReason?: Reason
  public get isValid(): boolean {
    return this.chosenReason != undefined;
  }

  navigate() {
    this._router.navigateByUrl("delete-account/channel")
  }
}

