import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { NgOtpInputModule } from 'ng-otp-input';
import { timer } from 'rxjs';
import { ApiServiceService } from '../services/api/api-service.service';
import { HttpErrorResponse } from '@angular/common/http';
import { StorageService } from '../services/storage/storage.service';

@Component({
  selector: 'app-code-input',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CommonModule, NgOtpInputModule],
  templateUrl: './code-input.component.html',
  styleUrl: './code-input.component.css'
})
export class CodeInputComponent implements OnInit {
  private _router: Router
  private _apiService: ApiServiceService
  private _storage: StorageService
  constructor(router: Router, apiService: ApiServiceService, storage: StorageService) {
    this._router = router
    this._apiService = apiService
    this._storage = storage
  }

  ngOnInit(): void {
    this.observableTimer()
    this.channel = this._storage.getChannel()
  }
  @ViewChild('ngOtpInput') ngOtpInputRef: any;

  numOfDigits: number = 6
  private code: string = ""
  private subscribeTimer: number = 0
  private timeLeft: number = 60
  private _error: Error | null = null
  private _loading: boolean = false

  channel: string | null = null
  isSuccess: boolean = false


  handleKeyEvent(event: any) {
    this.code = event
  }

  public get isValid(): boolean {
    return this.code.length == this.numOfDigits;
  }

  async complete(): Promise<void> {
    this._loading = true;
    this._error = null;
    (await this._apiService.confirmCode(this.code)).subscribe({
      error: err => {


        if (err instanceof Error) {
          this._error = err
          this._loading = false
          return
        }
        if (err instanceof HttpErrorResponse) {
          this._error = err
          this._loading = false
          return
        }
      },
      complete: () => {
        this._loading = false
        this.isSuccess = true
        this._storage.clear()
      }

    })

  }
  retry(): void {
    this._router.navigateByUrl('/delete-account/channel')
  }
  success(): void { }

  observableTimer() {
    const source = timer(0, 1000);
    const abc = source.subscribe(val => {
      this.subscribeTimer = this.timeLeft - val;
      if (this.subscribeTimer <= 0) {
        abc.unsubscribe()
      }
    });
  }

  public get timerValue(): string {
    return this.subscribeTimer.toString().padStart(2, '0')
  }

  public get showRetry(): boolean {
    return this.subscribeTimer <= 0
  }
  public get loading(): boolean {
    return this._loading
  }
  public get error(): Error | null {
    return this._error
  }

}
