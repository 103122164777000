<main class="bg-white w-screen flex px-2 sm:px-4 lg:px-0">

    <div *ngIf="!isSuccess"
        class="form-container rounded-lg lg:w-1/2 md:w-5/6 h-full m-auto border-t-8 border-t-primary shadow-md py-12 px-4 max-w-xl w-full">
        <h3 class="text-center font-bold mb-4">DELETAR MINHA CONTA</h3>
        <div class="flex flex-col">
            <p class="text-gray-plus mb-16">Insira o código enviado<span *ngIf="channel"> via <span class="font-bold">{{channel}}</span></span> </p>

            <ng-otp-input #ngOtpInput (onInputChange)="handleKeyEvent($event)" [config]="{length:numOfDigits}" class="mx-auto" />
            <span *ngIf="error!=null" class="text-center text-red mt-4">Código inválido</span>
            <p class="text-center text-gray-plus my-6">Não recebeu o código? Solicite novamente em: <span
                    class="font-bold">00:{{timerValue}}</span></p>
            <button [disabled]="!isValid||loading" (click)="complete()"
                class="mx-auto bg-primary min-w-64 text-white px-4 py-2 mt-4 rounded-md disabled:bg-gray font-bold mx-auto">
                <span *ngIf="!loading">Continuar</span>
                <span *ngIf="loading" class="loader mx-auto"></span>
            </button>
            <button *ngIf="showRetry" (click)="retry()"
                class="mx-auto bg-white min-w-64 text-primary px-4 py-2 mt-4 rounded-md disabled:bg-gray font-bold mx-auto border-2 border-primary hover:text-white hover:bg-primary">Solicitar
                código novamente</button>
        </div>
    </div>
    <div
    *ngIf="isSuccess"
    class="form-container rounded-lg lg:w-1/2 md:w-5/6 h-full m-auto border-t-8 border-t-primary shadow-md py-12 px-4 max-w-xl w-full">
    <h3 class="text-center font-bold mb-4">DELETAR MINHA CONTA</h3>
    <img src="../../assets/success.png" alt="" class="mx-auto my-16 w-1/3
    ">
    <p class="text-center mt-auto">
        Sua conta foi deletada com sucesso
    </p>
</div>

</main>