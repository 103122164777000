import { Component } from '@angular/core';
import { InputCpfComponent } from '../input-cpf/input-cpf.component';
import { InputBirthdateComponent } from '../input-birthdate/input-birthdate.component';
import { CommonModule } from '@angular/common';
import { ReasonsComponent } from '../reasons/reasons.component';
import { StorageService } from '../services/storage/storage.service';
import { Reason } from '../models/reason';
import { UserAccountRemovalInput } from '../models/user-account-removal-input';

@Component({
  selector: 'app-delete-account',
  standalone: true,
  imports: [InputCpfComponent, InputBirthdateComponent, ReasonsComponent, CommonModule],
  templateUrl: './delete-account.component.html',
  styleUrl: './delete-account.component.css'
})
export class DeleteAccountComponent {
  private storageService: StorageService
  constructor(storageService: StorageService) {
    this.storageService = storageService
  }

  step: number = 1;
  loading: boolean = false

  private date: string = ""
  private cpf: string = ""
  private reason: Reason = new Reason()

  handleCpf(cpf: string): void {
    this.cpf = cpf
    console.log(this.cpf)
    this.incrementStep()

  }
  handleBirthdate(date: string): void {
    this.date = date
    console.log(this.date)
    this.loading = !this.loading
    this.incrementStep()

  }
  handleReason(reason: Reason): void {
    this.reason = reason
    this.saveUserInput()

  }
  incrementStep(): void {
    this.step++
  }

  saveUserInput() {
    const data = new UserAccountRemovalInput(
      this.reason, this.cpf, this.date
    )
    this.storageService.saveUserInput(data)
  }


}
