import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from '../app/services/api/api-service.service';
import { CommonModule } from '@angular/common';
import { ContactChannel } from '../app/models/contact-channel';
import { Router } from '@angular/router';
import { StorageService } from '../app/services/storage/storage.service';

@Component({
  selector: 'app-contact-channel',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './contact-channel.component.html',
  styleUrl: './contact-channel.component.css'
})
export class ContactChannelComponent implements OnInit {

  private _apiService: ApiServiceService
  private _router: Router
  private _storage: StorageService
  constructor(apiService: ApiServiceService, router: Router, storage: StorageService) {
    this._apiService = apiService
    this._router = router
    this._storage = storage
  }
  ngOnInit(): void {
    this.fetchChannelData()
  }
  channels: ContactChannel[] = []

  selectecChannel: string = ""
  private _loading: boolean = false

  async fetchChannelData(): Promise<void> {
    (await this._apiService.getChannels()).subscribe(res => this.channels = res)
  }

  setChannel(channel: string) {
    this.selectecChannel = channel
  }

  translateChannel(rawChannel: string): string {
    let channel = `${rawChannel}:`

    switch (rawChannel.toLowerCase()) {
      case 'sms':
        channel = "SMS"
        break;
      case 'email':
        channel = "E-mail"
        break;
      case 'whatsapp':
        channel = "WhatsApp"
        break;

      default:
        break;
    }

    return channel
  }

  public get isValid(): boolean {
    return this.selectecChannel.length > 0;
  }

  async requestConfirmationCode(): Promise<void> {
    this._loading = true;
    (await this._apiService.requestCode(this.selectecChannel)).subscribe({
      error: err => console.log(err),
      complete: () => {
        this._loading = false
        this._storage.saveChannel(this.translateChannel(this.selectecChannel))
        this.navigate()
        return
      }

    })

  }
  public get loading(): boolean { return this._loading }
  navigate() {
    this._router.navigateByUrl("delete-account/code")
  }

}
