import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { CpfMaskDirective } from '../directives/cpf-mask.directive';

@Component({
  selector: 'app-input-cpf',
  standalone: true,
  imports: [CommonModule, CpfMaskDirective],
  templateUrl: './input-cpf.component.html',
  styleUrl: './input-cpf.component.css'
})
export class InputCpfComponent {

  @Output()
  onComplete:EventEmitter<string> = new EventEmitter();

  cpf:string =''

  getValue(value: string):void {
    this.cpf = value
  }

   complete():void{
    this.onComplete.emit(this.cpf.replace(/\D/g, ''))
  }
  public get isValid():boolean{
    return this.cpf.replace(/\D/g, '').length==11;
  }
}
