import { Injectable } from '@angular/core';
import { UserAccountRemovalInput } from '../../models/user-account-removal-input';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private storage: Storage;
  constructor() {
    this.storage = window.localStorage;

  }
  saveUserInput(input: UserAccountRemovalInput): void {
    this.storage.setItem('user_input', JSON.stringify(input))
  }
  getUserInput(): UserAccountRemovalInput | null {
    const data = this.storage.getItem('user_input')
    if (!data) return null
    return JSON.parse(data)
  }
  saveChannel(input: string): void {
    this.storage.setItem('user_channel', input)
  }
  getChannel(): string | null {
    const data = this.storage.getItem('user_channel')
    return data
  }
  clear(): void {
    this.storage.clear()
  }
}
