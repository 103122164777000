import { Routes } from '@angular/router';
import { DeleteAccountComponent } from './delete-account/delete-account.component';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { ContactChannelComponent } from '../contact-channel/contact-channel.component';
import { CodeInputComponent } from './code-input/code-input.component';

export const routes: Routes = [
    {path:'', component: HomeComponent},
    { path: "delete-account", component: DeleteAccountComponent },
    { path: "delete-account/channel", component: ContactChannelComponent },
    { path: "delete-account/code", component: CodeInputComponent },
];
