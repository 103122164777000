import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appCpfMask]',
  standalone: true
})
export class CpfMaskDirective {

  constructor() { }
  
  @HostListener('input', ['$event'])
  onInput(event: any) {
    const input = event.target;
    const trimmed = input.value.replace(/\D/g, '').substring(0, 11);

    if (trimmed.length > 9) {
      input.value = trimmed.replace(/^(\d{3})(\d{3})(\d{3})(\d{2}).*/, '$1.$2.$3-$4');
    } else if (trimmed.length > 6) {
      input.value = trimmed.replace(/^(\d{3})(\d{3})(\d{3}).*/, '$1.$2.$3');
    } else if (trimmed.length > 3) {
      input.value = trimmed.replace(/^(\d{3})(\d{3}).*/, '$1.$2');
    } else {
      input.value = trimmed;
    }
  }

}
