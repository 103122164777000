<div class="flex flex-col">
    <p class="text-gray-plus mb-8">Motivo da solicitação.</p>
   <label 
   *ngFor="let reason of reasons; index as i;"
   for="">
    <input 
    
    type="radio" name="i" id="i" (change)="onChange(reason)" [checked]="chosenReason?.id==reason.id">
    <span class="ml-3 text-gray-plus">{{reason?.label??""}}</span>
   </label>
   <label for="reasons-text" class="mt-6">
    <span class="text-gray-plus">Comentários (opcional)</span>
    <textarea [(ngModel)]="comment" class="w-full border border-gray rounded-md resize-none p-2 h-20" name="reasons-text" id="reasons-text"  cols="30" rows="10"></textarea>
   </label>
    
    <button [disabled]="!isValid" (click)="complete()" class="mx-auto bg-primary min-w-64 text-white px-4 py-2 mt-4 rounded-md disabled:bg-gray font-bold">Continuar</button>
</div>