import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-input-birthdate',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './input-birthdate.component.html',
  styleUrl: './input-birthdate.component.css'
})
export class InputBirthdateComponent {
  @Output()
  onComplete:EventEmitter<string> = new EventEmitter();

  @Input()
  loading:boolean=false;

  birthdate:string =''

  getValue(value: string):void {
    this.birthdate = value
  }

   complete():void{
    this.onComplete.emit(this.birthdate)
  }
  public get isValid():boolean{
    return this.birthdate.length>0;
  }

}
