import { Reason } from "./reason";

export class UserAccountRemovalInput {
    reason: Reason
    document: string
    birthdate: string

    constructor(reason: Reason, document: string, birthdate: string) {
        this.reason = reason
        this.document = document
        this.birthdate = birthdate

    }
}
