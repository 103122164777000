<main class="bg-white w-screen px-2 sm:px-4 lg:px-0  flex">

    <div class="form-container rounded-lg lg:w-1/2 md:w-5/6 h-full m-auto border-t-8 border-t-primary shadow-md py-12 px-4 max-w-xl w-full">
        <h3 class="text-center font-bold mb-4">DELETAR MINHA CONTA</h3>
       <app-input-cpf *ngIf="step==1" (onComplete)="handleCpf($event)"/>
    
       <app-input-birthdate  *ngIf="step==2" (onComplete)="handleBirthdate($event)" [loading]="loading"/>
       <app-reasons  *ngIf="step==3" (onComplete)="handleReason($event)"/>
    </div>
       
</main>