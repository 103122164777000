import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';
import { Router } from '@angular/router';
import { ContactChannel } from '../../models/contact-channel';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

const baseUrl = environment.baseUrl


@Injectable({
  providedIn: 'root'
})
export class ApiServiceService {
  private _httpClient: HttpClient
  private _storageService: StorageService
  private _router: Router
  constructor(httpClient: HttpClient, storageService: StorageService, router: Router) {
    this._httpClient = httpClient
    this._storageService = storageService
    this._router = router
  }

  
  async getChannels(): Promise<Observable<ContactChannel[]>>{
    const userData = this._storageService.getUserInput()
    if (!userData) this._router.navigateByUrl('delete-account')
   return this._httpClient.get<ContactChannel[]>(`${baseUrl}/web/persons/${userData?.document}/contact-channels?birthdate=${userData?.birthdate}`)
  }
  async requestCode(channel:string){
    const userData = this._storageService.getUserInput()
    if (!userData) this._router.navigateByUrl('delete-account')
    return this._httpClient.post(`${baseUrl}/web/persons/${userData?.document}/request-confirmation-code`,{channel})
  }
  async confirmCode(code:string):Promise<Observable<any>>{
    const userData = this._storageService.getUserInput()
    if (!userData) this._router.navigateByUrl('delete-account')
    const data ={
      "verificationCode": code,
      "reason": userData?.reason.label
    }
    return this._httpClient.delete(`${baseUrl}/web/persons/${userData?.document}`,{body:data})
  }
}
