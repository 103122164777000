<main class="bg-white w-screen flex px-2 sm:px-4 lg:px-0">

    <div
        class="form-container rounded-lg h-full m-auto border-t-8 border-t-primary shadow-md py-12 px-4 lg:w-1/2 md:w-5/6 max-w-xl w-full">
        <h3 class="text-center font-bold mb-4">DELETAR MINHA CONTA</h3>
        <div class="flex flex-col">
            <p class="text-gray-plus mb-16">Escolha como receber o código de confirmação.</p>

            <div *ngIf="channels.length>0; else loadingContainer" class="flex flex-col">
                <label *ngFor="let channelItem of channels" class="flex border-b border-gray py-4 text-gray-plus">
                    <input type="radio" name="channelItem.channel" id="channelItem.channel"
                        (change)="setChannel(channelItem.channel)" [checked]="channelItem.channel==selectecChannel">
                    <p class="ml-2"><span> {{translateChannel(channelItem.channel)}}:
                        </span>
                        <span> {{channelItem.data}}
                        </span>
                    </p>
                </label>
                <button [disabled]="!isValid || loading"
                    class="mx-auto bg-primary min-w-64 text-white px-4 py-2 mt-4 rounded-md disabled:bg-gray font-bold"
                    (click)="requestConfirmationCode()"><span *ngIf="!loading">Continuar</span>
                    <span *ngIf="loading" class="loader mx-auto"></span></button>
            </div>
            <ng-template #loadingContainer class="m-auto">

                <span class="loader mx-auto"></span>
            </ng-template>
        </div>
    </div>

</main>